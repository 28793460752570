<template>
    <PendingHistory :columns="fetchColumns" :rows="rows" :loadingStates="loadingStates" @onPageChange="onPageChange($event)" @onPerPageChange="onPageChange($event, true)" :paginationOptions="paginationOptions" :totalRows="pageData?.total_count" :filterOptions="filterOptions" :filterOptionsAsync="filterOptionsAsync.value" @onColumnFilters="onColumnFilters($event)" @getSearchOptionsTick="getSearchOptionsTick($event)" @clearFilters="clearFilters" :columnFilters="columnFilters" :key="currentPageData.page + pageData.total_count ? pageData?.total_count : 1" :due_date="due_date"/>
</template>

<script>
import PendingHistory from "@shared/components/pending-activity";
import axios from "@/axios";
import { uniqBy } from "lodash";
import { uuid } from "vue-uuid";
import { DateTime } from "luxon";
import { FormatDate } from "@/plugins/functions";

const columns = [
    {
        label: "Case ID",
        field: "visible_case_id",
        config: {
            filter: true,
            type: "link",
        },
    },
    {
        label: "Subject First Name",
        field: "candidate_first_name.label",
        query_key: "candidate_first_name",
        config: {
            filter: true,
        },
    },
    {
        label: "Subject Last Name",
        field: "candidate_last_name.label",
        query_key: "candidate_last_name",
        config: {
            filter: true,
        },
    },
    {
        label: "Activity Type",
        field: "activity_type.label",
        query_key: "activity_type",
        config: {
            filter: true,
        },
    },
    {
        label: "Status",
        field: "status.label",
        query_key: "status",
        config: {
            filter: true,
        },
    },
    {
        label: "Due Date",
        field: "due_date.label",
        query_key: "due_date.label",
        sortable: true,
        field_type: "date",
        config: {
            filter: true,
            filter_type: "date",
            query_keys: {
                start: "due_date_start_date",
                end: "due_date_end_date",
            },
            sortable_key: "due_date",
        },
    },
    {
        label: "Assignee Role",
        field: "assignee_role.label",
        query_key: "assignee_role",
        config: {
            filter: true,
        },
    },
    {
        label: "Assignee Name",
        field: "assignee",
        query_key: "assignee",
        config: {
            filter: true,
        },
    },
    {
        label: "Check",
        field: "check",
        query_key: "check",
        config: {
            filter: true,
        },
    },
    {
        label: "Title",
        field: "title.label",
        query_key: "title",
        sortable: true,
        config: {
            filter: true,
            type: "rich_text",
            sortable_key: "title",
        },
        tdClass: "truncate max-w-sm",
    },
];

export default {
    name: "activity-history-notification",
    data() {
        return {
            rows: [],
            loadingStates: {
                activity: false,
            },
            paginationOptions: {
                enabled: true,
                mode: "remote",
                perPage: 10
            },
            pageData: null,
            searchTimeout: null,
            currentPageData: {
                page: 1,
                count: 10,
            },
            searchLoading: {
                value: {},
            },
            filterOptionsAsync: {
                value: null,
            },
            columnFilters: null,
        };
    },
    components: {
        PendingHistory,
    },
    computed: {
        filterOptions() {
            return this.getFilterOptions(this.fetchColumns, this.rows);
        },
        fetchColumns() {
            return columns.map((el) => ({
                ...el,
                config: {
                    ...el.config,
                    isLoading: !!this.searchLoading.value[el.field],
                },
            }));
        },
    },
    async mounted() {
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.loadingStates.activity = true;
        await this.fetchPendingHistory();
    },
    methods: {
        FormatDate,
        async clearFilters() {
            this.columnFilters = null;
            await this.fetchPendingHistory(this.currentPageData.page, this.currentPageData.count);
        },
        async fetchPendingHistory(page, count, new_payload) {
            this.loadingStates.activity = true;
            const formatDate = (date) => (date ? DateTime.fromISO(date).toFormat("yyyy-LL-dd") : "");
            // console.log('new_payload :>> ', new_payload);
            let acknowledged = new_payload?.status ? new_payload.status.value !== "Unacknowledged" : null 
            
            try {
                let payload = {
                    activity_type: new_payload?.activity_type?.value || null,
                    assignee_role: new_payload?.assignee_role?.value || null,
                    assignee: new_payload?.assignee?.value || null,
                    acknowledged,
                    candidate_first_name: new_payload?.candidate_first_name?.value || null,
                    candidate_last_name: new_payload?.candidate_last_name?.value || null,
                    visible_case_id: new_payload?.visible_case_id?.value || null,
                    check: new_payload?.check?.value || null,
                    title: Array.isArray(new_payload?.title) ? new_payload?.title?.map((el) => el?.value) || []: new_payload?.title?.value || null,
                    due_date_start_date: formatDate(new_payload?.due_date_start_date?.name) || null,
                    due_date_end_date: formatDate(new_payload?.due_date_end_date?.name) || null,
                    page: page || 1,
                    count: count || 10,
                };
                const { data } = await axios.post(`dashboard-notifications/client`, payload);
                this.rows = data?.data?.data;
                const FormateDateRow = (date) => (date ? DateTime.fromISO(date).setZone('UTC').toFormat("dd MMM yyyy") : "");
                this.rows = this.rows.map((ele) => ({ ...ele, due_date: { value: FormateDateRow(ele.due_date.value), label: FormateDateRow(ele.due_date.label) } }));
                this.pageData = data?.data?.page;
                this.due_date = payload.due_date_start_date ? this.FormatDate(new Date(payload.due_date_start_date))+ '-' +this.FormatDate(new Date(payload.due_date_end_date)) : "Select date";
            } catch (error) {
                console.log(" error", error);
            }
            this.loadingStates.activity = false;
        },
        async onPageChange(data, resetPage=false) {
            if (resetPage) {
                // reset page to one before changing perpage
                this.currentPageData.page = 1;
            }
            let { currentPerPage, currentPage } = data;
            this.paginationOptions.perPage = currentPerPage;
            this.currentPageData = {
                page: currentPage,
                count: currentPerPage,
            };
            await this.fetchPendingHistory(currentPage, currentPerPage, this.columnFilters);
        },
        async onColumnFilters(data) {
            let payload = data.columnFilters;
            this.currentPageData.page = 1
            this.columnFilters = data?.columnFilters ? { ...data?.columnFilters } : null;
            await this.fetchPendingHistory(this.currentPageData.page, this.currentPageData.count, payload);
        },
        getSearchOptionsTick(payload) {
            console.log("payload :>> ", payload);
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload);
            }, delay);
        },

        async getSearchOptions(payload) {
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.$set(this.filterOptionsAsync, "value", null);
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading, "value", true);

                try {
                    let filters = {};
                    if (this.columnFilters) {
                        Object.entries(this.columnFilters).forEach(([key, value]) => {
                            let obj = {};
                            obj[key] = value.value;
                            filters = { ...filters, ...obj };
                        });
                    }
                    const requestData = {
                        ...filters,
                        [payload.column.query_key]: payload.value,
                    };
                    console.log("requestData :>> ", requestData);
                    const { data } = await axios.post(`dashboard-notifications/client`, requestData);
                    // set async options:
                    this.$set(this.filterOptionsAsync, "value", this.getFilterOptions(this.fetchColumns, data.data.data));
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading, "value", false);
            }
        },
        removeHTML(str) {
            let tmp = document.createElement("DIV");
            tmp.innerHTML = str;
            return tmp.textContent || tmp.innerText || "";
        },
        getFilterOptions(columns, rows) {
            return columns.reduce((result, curr) => {
                const fields = curr.field.split(".");
                result[curr.field] = rows
                    .filter((row) => {
                        return fields?.length === 1 ? row[curr.field] : row[fields[0]][fields[1]];
                    })
                    .map((row) => {
                        let obj = { id: uuid.v4(), name: fields?.length === 1 ? row[curr.field] : row[fields[0]][fields[1]], value: row[fields[0]]?.value };
                        if (!obj.value) {
                            obj.value = obj.name;
                        }
                        return obj;
                    });
                result[curr.field] = uniqBy(result[curr.field], "name");
                if (result["title.label"]) result["title.label"] = result["title.label"].map((el) => ({ ...el, name: this.removeHTML(el.name) }));

                return result;
            }, {});
        },
    },
};
</script>

<style></style>
